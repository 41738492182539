@tailwind base;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  a {
    @apply text-primary-dark underline;
  }
}

@tailwind components;
@tailwind utilities;


body {
  @apply font-sans;
  @apply bg-secondary;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
