body {
  scrollbar-gutter: stable;
}

#root {
  position: relative;
  z-index: 1;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../fonts/Roboto-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../fonts/Roboto-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../fonts/Roboto-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../fonts/Roboto-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../fonts/Roboto-BoldItalic.woff2') format('woff2');
}
